@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-black: #070707;
  --primary-white: #fbfbfb;
  --primary-gray: #473c30;
  --secondary-white: #999999;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 62.5%;
  font-family: "Montserrat", sans-serif;
  //  scroll-behavior: smooth;
}

body {
  background-color: var(--primary-black);
  color: var(--primary-white);
}

// Default setting for number input
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: rgb(0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-gray);

  border-radius: 100dvb;
}

// .scroll-watcher{
//     width: 0%;
//     height: 2px;
//     background-color: white;
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: 999;
//     animation: scrollwatch 2s linear;
//     animation-timeline: scroll();
//     @keyframes scrollwatch {
//         100%{
//            width: 100%;
//         }
//     }
// }

.max-width {
  max-width: 1240px;
  margin: 0 auto;

  @media (width<=1240px) {
    padding: 0 1.4rem;
  }
}

.layout-section {
  padding: 4rem 0;

  @media (width<768px) {
    padding: 2.4rem 0;
  }
}

.pageBanner {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  height: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;

  @media (width<1024px) {
    height: 400px;
  }

  @media (width<600px) {
    height: 280px;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(5, 4, 4, 0.4);
    z-index: -1;
  }
}

.pageBanner-h1 {
  font-size: 5.5rem;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--primary-white);

  @media (width<768px) {
    font-size: 2.5rem;
  }
}

.heading-h2 {
  font-size: 1.6rem;
  text-transform: uppercase;
  color: var(--primary-gray);
  font-weight: 500;
}

.heading-h3 {
  font-size: 3.2rem;
  line-height: 4rem;
  font-weight: 700;

  @media (width<768px) {
    font-size: 2.4rem;
    line-height: 3rem;
  }
}

.para {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  color: var(--secondary-white);

  @media (width<=600px) {
    font-size: 1.4rem;
    text-align: justify;
  }
}

.common-btn {
  background-color: var(--primary-gray);
  color: var(--primary-white);
  border-radius: 40px;
  padding: 1rem 3rem;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: 500;

  border: 1.8px solid #8e7861;
  &:active {
    scale: 0.9;
  }
  border: 1.8px solid #8e7861;

  &:active {
    scale: 0.9;
  }

  @media (width<600px) {
    font-size: 1.3rem;
  }
}

// Swiper bulltes customization*****
.swiper-pagination-bullets {
  width: fit-content !important;

  .swiper-pagination-bullet {
    width: 20px;
    height: 1.4px;
    border-radius: 0;
    background-color: white;

    @media (width<=600px) {
      width: 10px;
    }
  }

  .swiper-pagination-bullet-active {
    width: 48px;
    height: 2px;
    border-radius: 0;
    background-color: white;

    @media (width<=600px) {
      width: 20px;
    }
  }
}

.LazyLoad {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.is-visible {
  opacity: 1;
}

.insta {
  .swiper-button-prev,
  .swiper-button-next {
    width: 5.5rem;
    height: 5rem;
    background-color: rgba(255, 255, 255); /* Semi-transparent background */
    color: #0d0120; /* Default icon color */
    
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: rgba(0, 0, 0); /* Darker on hover */
      color: #eeeeee;
    }

    &::after {
      font-weight: 900;
      font-size: 2rem;
    }
    @media screen and (max-width: 768px) {
      width: 1.5rem;
      height: 1.5rem;

      &::after {
        font-size: 0.8rem;
      }
    }
  }
  .swiper-button-disable {
    opacity: 0;
  }
}

.insta{
  .swiper-button-prev {
    left: -2rem;
    border-radius: 0 50%  50% 0; /* Make the buttons circular */
  }
  .swiper-button-next {
    right: -2rem;
    border-radius: 50% 0 0 50%  ; /* Make the buttons circular */
  }
}