.work-card{
    display: flex;
    gap: 3rem;
    &:nth-child(even){
        flex-direction: row-reverse;
        @media (width<1024px) {
            flex-direction: column;
        }
    }
    @media (width<1024px) {
        flex-direction: column;
    }
}